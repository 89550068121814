.fontTab-fontSize {
}

#block-secondary-font-family {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}
#block-secondary-font-family .preview-family-name {
  padding-bottom: 0.4rem;
  padding-top: 4rem;
}
#block-secondary-font-family .preview-family-text {}
#block-secondary-font-family .ant-form-item-label {
  text-align: left;

}
