.table-row {
  color: black;
  line-height: initial;
  word-wrap: initial;
  word-spacing: initial;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.table-name {
  z-index: 5;
  background-color: rgba(0, 0, 100, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.editable-cell {
  position: relative;
}

#table-wrapper {
  position: relative;
}

.ant-table-container {
  border-inline: none !important;
}
.ant-table-container > .ant-table-content > table {
  border: none !important;
}

.ant-table {
  font-size: 12px !important;
  word-wrap: initial;
  word-spacing: initial;
  line-height: initial;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.ant-table-tbody {
  background-color: white;
}

.custom-table .ant-table tr:hover td.ant-table-cell-row-hover {
  background-color: inherit !important;
}

.ant-table-wrapper .ant-table-container,
.ant-table-wrapper table {
  border-radius: 0px !important;
}

.ant-table.ant-table-small td,
.ant-table.ant-table-small th {
  padding: 0px 1px !important;
}

.ant-table.ant-table-middle td,
.ant-table.ant-table-middle th {
  padding: 4px 0px !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr:not(.ant-table-measure-row) > td {
  font-size: 12px !important;
  word-wrap: initial;
  word-spacing: initial;
  line-height: initial;
  padding: 8px 4px !important;
  /* padding: 0px !important; */
}

.ant-table-thead > tr > th::before,
.ant-table-tbody > tr > td::before {
  display: none;
}

.custom-table table {
  border-collapse: collapse;
}
.custom-table table thead tr:first-child th:first-child,
.custom-table table thead tr:first-child th:last-child {
  border-radius: 0 !important;
}

*:not(.custom-table) table .tableSelectedRow,
*:not(.custom-table) table tr.tableSelectedRow:hover,
*:not(.custom-table)
  table
  tr.tableSelectedRow:hover
  td.ant-table-cell-row-hover {
  background-color: var(--primary-color-light) !important;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border-left: none;
}

.column-line-hovered {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background-color: #388bff;
    width: 4px;
    height: calc(100% + 2px);
    right: 0;
    top: -2px;
    transform: translateX(calc(2px + 1px));
    z-index: 1;
  }
}

.is-header-row {
  &:before {
    height: 100%;
    top: 0;
  }
}

.is-first-column {
  &:before {
    left: 0;
    transform: translateX(calc(-2px - 1px));
  }
}

.row-line-hovered {
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    cursor: pointer;
    position: absolute;
    background-color: #388bff;
    width: calc(100% + 2px);
    height: 4px;
    left: 0;
    bottom: 0;
    transform: translateY(calc(2px + 1px));
    z-index: 1;
  }
}

.is-first-row {
  &:before {
    top: 0;
    transform: translateY(calc(-2px - 1px));
  }
}

.table-border-hovered {
  border-color: #388bff !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
}

.table-border-clicked {
  &:after {
    height: 100%;
    width: 100%;
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: rgba(179, 212, 255, 0.3);
  }
}

.control-row-hovered {
  background-color: #85b8ff !important;
  border-color: #388bff !important;
}

.table-border-clicked-to-delete {
  border-color: #ff4d4f !important;
  &:after {
    background-color: rgba(255, 77, 79, 0.3);
  }
  .control-row-hovered {
    border-color: #ff4d4f !important;
    background-color: #fb8787 !important;
  }
}

