body {
  font-family: 'Montserrat';
}

.right-side-wrapper {
  overflow: auto;
  min-height: calc(100% - 54px);
  position: fixed;
  background-color: #0b2239ff;
  margin-top: 54px;
  padding: 18px 0;
}

.menu-item {
  text-align: center;
  padding: unset !important; //ant ajoute un padding: 0 16px; et il y a aussi un padding-left: 0px; non-identifié
  margin: 12px 0;
}

.right-text-button {
  left: 5px;
}

.copy-item-wrapper {
  width: 100%;
  padding-bottom: 20px;
}

.copy-item-text {
  text-align: center;
}

.collapse-right-button {
  position: fixed;
  top: calc(50% - 5px);
  right: 210px;
  width: 40px;
  height: 50px;
  background-color: #0b2239ff;
  border-radius: 5px;
  transition: all 0.2s;
}

.not-deployed-right {
  transform: translateX(+220px);
}

.right-arrow-wrapper {
  padding-top: 14px;
  padding-left: 8px;
  color: #ffffff;
}
.menu_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.style_btn_right_side {
  width: 185px;
  border-radius: 5px;

  text-align: center;
  padding: unset !important;
}
#generate-pdf-button {
  display: flex;
  justify-content: center;
}

#global-menu {
  height: 100%;
}

.menu_wrapper {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.menu_wrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

