.variable-selection-line-wrapper.create {
  &:after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: #00000026;
    position: absolute;
    bottom: 0;
  }
}

