.trigger-array {
  .ant-table-placeholder {
    display: none;
  }
  .ant-table-footer {
    padding: 0 !important;
    border: none !important;
    border-radius: 0;
    background-color: white;
  }
}

.trigger-select-type {
  width: fit-content !important;
}

.trigger-select-name {
  width: 200px !important;
}

