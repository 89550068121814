.variable-editor__form {
  .ant-form-item-label {
    label {
      color: white !important;
      font-weight: bold !important;
    }
  }
}

.variable-editor__middle {
  .ant-form-item-label {
    label {
      width: 210px;
      justify-content: start;
    }
  }

  .ant-col-24.ant-form-item-label {
    label {
      width: 100%;
    }
  }
}

// .variable-editor__middle_section {
//   .ant-form-item-label {
//     label {
//       width: 150px;
//       justify-content: start;
//     }
//   }
//   .ant-col-24.ant-form-item-label {
//     label {
//       width: 100%;
//     }
//   }
// }

.variable-editor__input {
  div.DraftEditor-root {
    background-color: #ffffff;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
    &:hover {
      border: 1px solid #ff954f;
    }
  }
}
.variable-editor__input.disabled {
  div.DraftEditor-root {
    color: silver;
    cursor: not-allowed;
  }
}

.variable-editor__input.remark {
  div.DraftEditor-root {
    max-height: var(--remark-max-height);
    min-height: 100px;
  }
  div.DraftEditor-editorContainer,
  div.public-DraftEditor-content {
    max-height: var(--remark-max-height);
    min-height: 100px;
  }
}

.label-array {
  .ant-form-item-label {
    label {
      width: 130px;
    }
  }
}

.variable-editor__input.name {
  .ant-form-item-label {
    label {
      width: 100px;
      justify-content: start;
    }
  }
  div.DraftEditor-root {
    max-height: 80px;
  }
}

.variable-editor__input.focused {
  div.DraftEditor-root {
    border: 1px solid #ff954f;
  }
}

.variable-editor__input.auto {
  .ant-form-item-label {
    label {
      width: auto;
    }
  }
}

.form-box-create-modal {
  .ant-modal-content {
    background-color: #0b2239;
    border: 1px solid white;
  }
}

.border-icon-name {
  &:after {
    content: '';
    height: 3px;
    width: 100%;
    position: absolute;
    background-color: gray;
    bottom: -6px;
  }
}

.form-box-editor-name {
  .public-DraftStyleDefault-block > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.variable-editor__middle-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 8px;
  gap: 8px;
  white-space: nowrap;
  &::after {
    content: '';
    height: 1.5px;
    background-color: white;
    flex: 1;
  }
  &::before {
    content: '';
    height: 1.5px;
    background-color: white;
    flex: 1;
  }
}

.form-box-create-modal-mask {
  background-color: rgb(0 0 0 / 80%) !important;
}

.form-item-array-row-changed {
  animation: blink 1.6s ease-in-out;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    background-color: #f17024;
    color: #211107;
    opacity: 0.5;
  }
}

