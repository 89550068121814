.adder-container-menu-button {
  display: flex;
  gap: 1px;
  height: 100%;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  background: #f5f5f5;
  cursor: pointer;
  box-shadow: -1px 0px 1px #e9e9e9, 0px 1px 1px #e9e9e9, -2px 1px 1px #e9e9e9,
    -1px 2px 1px #e9e9e9, -3px 2px 1px #e9e9e9, -2px 3px 1px #e9e9e9;
  transition: all 0.1s linear;
  transform: translateZ(0);
  &:active {
    box-shadow: none;
    transform: translate3d(-3px, 3px, 0);
  }
}

