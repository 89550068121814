.input-hideable.appear {
  animation: inputAppear 300ms ease-in-out;
  transform-origin: center right;
}

.input-hideable.disappear {
  animation: inputDisappear 300ms ease-in-out;
  transform-origin: center right;
}

@keyframes inputAppear {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

@keyframes inputDisappear {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(0);
  }
}

