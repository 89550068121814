body {
  font-family: 'Montserrat';
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collapse-left-button {
  position: fixed;
  top: calc(50% - 5px);
  left: 195px;
  width: 40px;
  height: 50px;
  background-color: #0b2239ff;
  border-radius: 5px;
  transition: all 0.2s;
}

.not-deployed-left {
  transform: translateX(-220px);
}

.left-arrow-wrapper {
  padding-top: 14px;
  padding-left: 15px;
  color: #ffffff;
}

.left-side {
  overflow: auto;
  // position: fixed;
  left: 0;
  background-color: #0b2239ff;
  overflow-x: hidden;
  margin-top: 54px;

  &__container {
    display: flex;
    flex-direction: column;
    background-color: #0b2239ff;
    height: 100%;
  }

  // &__logo-container {
  //   width: 100%;
  //   text-align: center;
  //   margin-top: -30px;
  // }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__undo-redo {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__summary {
    padding: 0 10px;
    &__item {
      color: white;
      font-size: 16px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: var(--primary-color);
      }

      &--t2 {
        color: white;
        font-weight: 200;
        text-decoration: none;
        cursor: pointer;
        margin-left: 6.5%;

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}

.left-side__wrapper {
  animation: wrapperAppear 0.5s;
}

@keyframes wrapperAppear {
  from {
    opacity: 0.01;
  }
  to {
    opacity: 100;
  }
}

.left-side__summary {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.left-side__summary::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.ant-tree-node-selected {
  background-color: transparent !important; /* ou toute autre couleur que vous souhaitez */
}
.container-title-summary:hover {
  .trash-icon {
    display: block;
  }
}
.trash-icon {
  display: none;
};

// .ant-tree .ant-tree-node-content-wrapper {
//   padding: 0px 0px;
// }

// .ant-tree-switcher_close {
//   width: 13px !important;
// }

// .ant-tree-switcher_open {
//   width: 13px !important;
// }

// .ant-tree-switcher-noop {
//   width: 13px !important;
// }

// .ant-tree-indent-unit {
//   width: 14px !important;
// }

// .draggable-tree .ant-tree-treenode {
//   padding-inline-start: 14px !important; /* Modifiez la valeur selon vos besoins */
// }


// .custom-tree .ant-tree-treenode {
//   padding-left: 20px !important; /* Ajustez à votre convenance */
// }

// .ant-tree-switcher-leaf-line {
//   width: 14px !important; /* Ajustez à votre convenance */
// }
.ant-tree-treenode {
  width: 100% !important;
}

.ant-tree-node-content-wrapper{
  width: 100% !important;
}

.ant-tree-switcher-leaf-line::after {
  width: 7px !important;
  border-bottom: 1px solid #7d7d7d !important;
}

.ant-tree-indent-unit::before {
  border-inline-end: 1px solid #7d7d7d  !important;
}

.ant-tree-switcher-leaf-line::before {
  border-inline-end: 1px solid #7d7d7d  !important;
}

.dragging {
  opacity: 0.5 !important;
}

