@import '~antd/dist/reset.css';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  word-break: normal;
  word-wrap: normal;
  color: black;
  background-color: #f0f2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #f17024;
  --primary-color-light: rgba(241, 112, 36, 0.3);
}

.body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.body::-webkit-scrollbar-corner {
  display: none;
}
.Drag {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: grab;
  z-index: 100000000;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  outline: 1px solid #74b9ff;
}

.Drag-active {
  cursor: grabbing;
}

.Drag-hide-grab {
  cursor: auto;
}

.hidden {
  display: none;
}

h1 {
  font-size: 36px !important;
}

h2 {
  font-size: 30px !important;
}

h3 {
  font-size: 24px !important;
}

h4 {
  font-size: 20px !important;
}

h5 {
  font-size: 18px !important;
}

h6 {
  font-size: 16px !important;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-layout-header {
  line-height: 0;
}

.scale-on-hover {
  &:hover {
    transform: scale(1.3);
    transition: transform 200ms ease-in-out;
  }
}

@border-radius-base: 5px;
@border-color-base: #d9d9d9;
@primary-color: #f17024;

