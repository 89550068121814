.selector-variable {
  .ant-select-selector {
    background-color: transparent !important;
    height: unset !important;
  }

  .ant-select-selection-item {
    line-height: unset !important;
  }
  .ant-select-selector:after {
    line-height: unset !important;
  }
}

.ant-select-clear {
  color: #ff4d4f !important;
}

