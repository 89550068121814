.container-collapse {
  display: flex;
  align-items: center;
}

#collapsed {
  font-size: 12px;
   border-radius: 10px;
  padding: 2px;

}
#collapsed:hover {
  cursor: pointer;
  background-color: #F5F5F5;

}
