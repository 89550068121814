:root {
  --text-clr: ;
  --cell-clr: ;
  --header-clr: rgb(243, 243, 243);
  --header-txt-clr: ;
  --strippedColr1: #ffffff;
  --strippedColr2: #f1f1f1;
  --text-clr1: ;
  --text-clr2: ;
}

.table-row-light {
  background-color: var(--strippedColr1);
  color: var(--text-clr1);
}
.table-row-dark {
  background-color: var(--strippedColr2);
  color: var(--text-clr2);
}

.text-clr {
  color: var(--text-clr1);
}

.cell-clr {
  background-color: var(--cell-clr);
}

.header-clr {
  background-color: var(--header-clr);
}

.table-color-red {
  color: red;
}

.table-color-blue {
  color: blue;
}

.ant-table-thead > tr > th {
  color: var(--header-txt-clr);
  background: var(--header-clr);
}
