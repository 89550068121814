body {
  font-family: 'Montserrat';
}

.navbar {
  width: 100%;
  background-color: #0b2239;
  border-bottom: 1px solid #ffffff;
  z-index: 1;
  padding: 0%;
  display: flex;
  // justify-content: space-evenly;
  flex-wrap: nowrap;

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }

  &__logo-container {
    text-align: center;
    display: flex;
  }

  &__logo {
    display: flex;
  }

  &__text-properties-container {
    display: inline-block;
    margin-top: 0;
    // height: 54px;
  }

  &__table-properties-container {
    display: inline-block;
    width: 170px;
  }

  &__img-properties-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__common-properties-container {
    display: block;
  }

  &__right-properties-container {
    display: flex;
    align-items: center;
    width: 9%;
    justify-content: space-evenly;
  }

  &__namerole-properties-container {
    display: inline-block;
    width: 300px;
    justify-content: flex-end; /* Horizontally space-out items */
  }

  &__formshape-properties-container {
    display: inline-block;
  }

  &__topmid-properties-container {
    display: flex;
    justify-content: center;
  }

  &__bottommid-properties-container {
    width: auto;
    display: flex;
    margin-left: 0;
  }

  &__topleft-properties-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__bottomleft-properties-container {
    width: 145px;
    display: flex;
    margin-left: 0;
    display: flex;
    vertical-align: center;
    text-align: center;
  }

  &__imgbotmid-properties-container {
    display: flex;
    margin-left: 0;
  }

  &__fstop-properties-container {
    display: flex;
    margin-left: 0;
  }

  &__fsbot-properties-container {
    width: 235px;
    display: flex;
    margin-left: 0;
  }

  &__commontop-properties-container {
    width: 100%;
    display: flex;
    margin-left: 0;
  }

  &__commonbot-properties-container {
    width: 100%;
    display: flex;
    margin-left: 0;
  }

  &__name-properties-container {
    width: 300px;
    display: flex;
    text-align: center;
    // flex: 2;
    flex-direction: column;
  }

  &__role-properties-container {
    width: 150px;
    display: flex;
  }
}

.avatar-wrapper {
  // display: flex;
  text-align: center;
  height: 42px;
  width: 40px;
  margin-top: -2%;
  // margin-right: auto;
}

.user-infos-wrapper {
  display: inline-flex;
  height: 54px;
  flex-wrap: wrap;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  margin-right: 5px;
  position: relative;
  right: 5;
}

.name-and-position-wrapper {
  display: flex;
  width: 30%;
  height: 54px;
  flex: 4;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3%;
}

.customUpload {
  // background-color: pink;
  margin-top: -30%;
  margin-right: -50%;
  font-size: 1px;

  width: '10px';
  height: '10px';
  border: '1px solid #124476';
  border-radius: '5px';

  padding: '2px';
  display: 'flex';
}

.customButton:hover {
  color: #f17024;
}

input {
  width: 80px;
}

.navbar__logo-container {
  margin-left: 15px;
  position: absolute;
  left: 8px;
  display: flex;
  align-items: center;
}

.navbar__logo {
  max-height: 50px; /* Adjust the height based on your design */
  max-width: 115px; /* Ensure the logo doesn't exceed 115px in width */
}
@media screen and (max-width: 1188px) {
  .navbar__logo-container {
    display: none;
  }
}

.ant-select-item-option-content {
  overflow: visible;
}

