.table-with-drag {
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table {
    border: none !important;
  }
  .ant-table-thead {
    th:last-child {
      border-inline-end: none !important;
    }
  }
  .table-with-drag-row-selected > .ant-table-cell {
    &:hover {
      background-color: #c3d6fb !important;
    }
    background-color: #d8e3f9 !important;
  }
}

