.zone-dmz {
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 15px,
    #8ad2ff 15px,
    #8ad2ff 20px
  );
  opacity: 0.4;
  outline: 4px solid #8ad2ff;
  border-radius: 2px;
}

