select {
	height: 30px;
	border-radius: 10px;
	text-align: center;
	margin-top: 0.5%;
}

option {
	text-align: center;
}
