:root {
  --unorderedSymbol0: ;
  --unorderedSymbol1: ;
  --unorderedSymbol2: ;
  --unorderedSymbol3: ;
  --unorderedSymbol4: ;
  --customBulletPaddingCSS: '';
  line-height: 1.5715;
}
.RichEditor-ordered-list-item-no-marker::marker {
  content: '' !important;
}

.public-DraftStyleDefault-ul ul {
  list-style: none;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 0 !important;
}

.public-DraftStyleDefault-unorderedListItem {
  position: relative;
  list-style-type: none !important;
  .public-DraftStyleDefault-block > :first-child {
    position: relative;
    &::before {
      text-decoration: none;
      font-style: normal;
      position: absolute;
      left: calc(-1em - 5px);
      width: 1em;
      height: 1em;
      top: 0;
    }
  }
  padding: var(--customBulletPaddingCSS);
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  .public-DraftStyleDefault-block > :first-child::before {
    content: var(--unorderedSymbol0);
    font-family: 'Montserrat';
  }
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  .public-DraftStyleDefault-block > :first-child::before {
    content: var(--unorderedSymbol1);
    font-family: 'Montserrat';
  }
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth2 {
  .public-DraftStyleDefault-block > :first-child::before {
    content: var(--unorderedSymbol2);
    font-family: 'Montserrat';
  }
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth3 {
  .public-DraftStyleDefault-block > :first-child::before {
    content: var(--unorderedSymbol3);
    font-family: 'Montserrat';
  }
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth4 {
  .public-DraftStyleDefault-block > :first-child::before {
    content: var(--unorderedSymbol4);
    font-family: 'Montserrat';
  }
}

.RichEditor-editor {
  cursor: text;
  width: 100%;
  height: 100%;
}

.RichEditor-styleButton {
  margin: auto;
  font-size: 14px;
  position: relative;
}

.DraftEditor-editorContainer {
  border: none;
  padding: var(--padding);
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  border-spacing: 10px;
  color: #666;
  font-style: italic;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
}

.RichEditor-controls {
  display: flex;
  font-size: 14px;
  font-family: 'Montserrat';
  margin-right: 12;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.DraftEditor-root {
  height: auto;
  width: 100%;
}
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  height: 100%;
}

.public-DraftStyleDefault-block {
  /* display: flex;
  flex-wrap: wrap; */
}

.public-DraftStyleDefault-ltr {
  /* display: flex;
  flex-wrap: wrap; */
}

.ant-select-dropdown .ant-select-item {
  padding: 5px 8px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0px 7px !important;
}
