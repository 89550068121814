.site-layout-background {
  /* padding: 24px; */
  margin: 0;
  min-height: 280px;
}

/* ADD AND REMOVE PAGE */

.add-remove-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  text-align: center;
}
.bottom-page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  text-align: center;
}
