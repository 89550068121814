/* FamilyPicker component */
/* remove ant style from transfer component */
.transfer-remove-icon * .ant-transfer-list-content-item-remove {
  display: none !important;
}
.ant-transfer-list-header {
  display: none;
}

/* COLUMN */
.column-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 0.5rem;
  font-family: 'Montserrat';
}

.column-item {
  margin-bottom: 10%;
  margin-left: auto;
  margin-right: auto;
}

/* ROW */
.row-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* SPACER */
.spacer {
  flex: 1;
}

.handleBox {
  display: flex;
}

/* COLOR SELECTOR */

.color-selector > .ant-select-selector {
  padding-left: 0px !important;
}

.ant-select-selector {
  font-family: inherit !important;
}

#font-size-control .ant-select-selector {
  border-radius: inherit !important;
}

.element {
  width: 100%;
  height: 100%;
  animation: pulse 5s infinite;
}
/*
@keyframes sliding {
  0% {
    box-shadow: 0 0 13px blue;
  }
  50% {
    box-shadow: 0 0 15px blue;
  }
  100% {
    box-shadow: 0 0 13px blue;
  }
} */

.box-sliding {
  /* border: 1px solid purple; */
  box-shadow: 0 0 10px blue;
  /* animation: sliding 1s infinite; */
}
