:root {
  --globalFontWeight: 400;
}

.middle-page {
  display: flex;
  flex-direction: row;
}

.middle-page__page-count {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.middle-page__masks {
  margin-left: 10px;
}

.move-bar {
  position: relative;
  width: 60px;
  border: 1px solid black;
  border-style: dashed;
  border-right: none;
  background-color: transparent;
  user-select: none;
  cursor: row-resize;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}
.move-bar.forbidden-slider {
  cursor: not-allowed;
}
.no-scrollbar {
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}
.no-scrollbar::-webkit-scrollbar {
  /* display: none; Safari and Chrome */
  width: 0;
  background: transparent;
}

#pdf-html-source .page *::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}
#pdf-html-source .page *::-webkit-scrollbar-corner {
  display: none;
}
#pdf-html-source
  .page
  *:not(
    .public-DraftStyleDefault-unorderedListItem,
    .public-DraftStyleDefault-orderedListItem
  ) {
  overflow: hidden !important;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
}

.move-cursor {
  display: none;
  width: 40px;
  height: 40px;
}

.move-cursor-icon {
  font-size: 40px;
}

.move-bar:hover .move-cursor {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.move-bar:hover .move-cursor::after {
  position: absolute;
  top: 18px;
  left: 0px;
  z-index: 10000;
  content: '';
  height: 4px;
  background-color: #535455;
}

/* */

.grid {
  margin: 0;
  width: 100%;
  height: 100%;
}

.nameColumn {
  cursor: pointer;
}
